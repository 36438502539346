import "../../styles/customerRecord/customerRecord.css";
import React, { useState } from "react";
import { getIcon } from "../../utils/iconUtils";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { format } from "date-fns";
import { checkMarketAu, validateUserPermission } from "../../utils/helpers";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";
import DeskingSheet from "./DeskingSheet";
import { PDFExport } from "@progress/kendo-react-pdf";

const FinanceRecord = ({
  marketSettings,
  financeData,
  customerRecordData,
  clientPositionData,
  currentUser,
  status,
  pdfExportComponent,
  exportDeskingSheet,
  formatCurrency
}) => {
  const { t } = useTranslation();
  const [showNewUsedDropdown, setShowNewUsedDropdown] = useState(true);
  const [showEquityDropdown, setShowEquityDropdown] = useState(true);
  const [showTotalDepositDropdown, setShowTotalDepositDropdown] =
    useState(true);
  const [showTermKilometerDropdown, setShowTermKilometerDropdown] =
    useState(true);
  const Navigate = useNavigate();
  const { id } = useParams();
  let location = useLocation();

  const currencyOptions = {
    symbol: marketSettings.currencySymbol
  };

  const financeRecordData = [
    {
      details: getTranslation("Description", t),
      existing: financeData?.existing?.description,
      proposed: financeData?.proposed?.description
    },
    {
      details: getTranslation("Full Name", t),
      existing: financeData?.existing?.fullName,
      proposed: financeData?.proposed?.fullName
    },
    {
      details: getTranslation("New/Used", t),
      existing: getTranslation(financeData?.existing?.newOrUsed, t),
      proposed: getTranslation(financeData?.proposed?.newOrUsed, t)
    },
    {
      details: getTranslation("Registration date", t),
      existing: financeData?.existing?.registrationDate
        ? format(
            new Date(financeData?.existing?.registrationDate),
            "dd/MM/yyyy"
          )
        : null,
      proposed: financeData?.proposed?.registrationDate
        ? format(
            new Date(financeData?.proposed?.registrationDate),
            "dd/MM/yyyy"
          )
        : null
    },
    {
      details: getTranslation("Start date", t),
      existing: financeData?.existing?.startDate
        ? format(new Date(financeData?.existing?.startDate), "dd/MM/yyyy")
        : null,
      proposed: "N/A"
    },
    {
      details: getTranslation("Start kilometers", t),
      existing:
        financeData?.existing?.startKilometers === null
          ? "0"
          : financeData?.existing?.startKilometers,
      proposed: financeData?.proposed?.startKilometers
    },
    {
      details: getTranslation("Start age", t),
      existing:
        financeData?.existing?.startAge === null
          ? "0"
          : financeData?.existing?.startAge,
      proposed: financeData?.proposed?.startAge
    },
    {
      details: getTranslation("License Plate", t),
      existing: financeData?.existing?.licencePlate,
      proposed: financeData?.proposed?.licencePlate
    },
    {
      details: getTranslation("VIN", t),
      existing: financeData?.existing?.vin,
      proposed: financeData?.proposed?.vin
    },
    {
      details: getTranslation("Payments remaining", t),
      existing: financeData?.existing?.paymentsRemaining,
      proposed: "N/A"
    },
    {
      details: getTranslation("Term/Total Km", t),
      existing: `${financeData.existing?.term ?? 0} / ${
        financeData.existing?.totalKilometers ?? 0
      } km`,
      proposed: `${
        !financeData.proposed?.term ? 0 : financeData.proposed?.term
      } / ${financeData.proposed?.totalKilometers ?? 0} km`
    },
    {
      details: getTranslation("Term", t),
      existing: financeData?.existing?.term,
      proposed: financeData?.proposed?.term
    },
    {
      details: getTranslation("Total Kilometers", t),
      existing:
        financeData?.existing?.totalKilometers === null
          ? "0 km"
          : `${financeData?.existing?.totalKilometers} km`,
      proposed:
        financeData?.proposed?.totalKilometers === null
          ? "0 km"
          : `${financeData?.proposed?.totalKilometers} km`
    },
    {
      details: getTranslation("Finance Type", t),
      existing: getTranslation(financeData?.existing?.financeTypeName, t),
      proposed: getTranslation(financeData?.proposed?.financeTypeName, t)
    },
    {
      details: getTranslation("Interest rate", t),
      existing: financeData?.existing?.interestRateFormatted
        ? `${financeData?.existing?.interestRateFormatted}%`
        : null,
      proposed: financeData?.proposed?.interestRateFormatted
        ? `${financeData?.proposed?.interestRateFormatted}%`
        : null
    },
    {
      details: getTranslation("Comparison Rate", t),
      existing: financeData?.existing?.comparisonRate
        ? financeData?.existing?.comparisonRateFormatted + "%"
        : financeData?.existing?.comparisonRate === 0
        ? "0%"
        : "N/A",
      proposed: financeData?.proposed?.comparisonRate
        ? financeData?.proposed?.comparisonRateFormatted + "%"
        : financeData?.proposed?.comparisonRate === 0
        ? "0%"
        : "N/A"
    },
    {
      details: getTranslation("Equity", t),
      existing: financeData?.existing?.equityFormatted
        ? formatCurrency(
            financeData?.existing?.equityFormatted,
            currencyOptions.symbol
          )
        : null,
      proposed: "N/A"
    },
    {
      details: getTranslation("Trade-in Value", t),
      existing: financeData?.existing?.tradeInValueFormatted
        ? formatCurrency(
            financeData?.existing?.tradeInValueFormatted,
            currencyOptions.symbol
          )
        : null,
      proposed: "N/A"
    },
    {
      details: getTranslation("Settlement Value", t),
      existing: financeData?.existing?.settlementValueFormatted
        ? formatCurrency(
            financeData?.existing?.settlementValueFormatted,
            currencyOptions.symbol
          )
        : null,
      proposed: "N/A"
    },
    {
      details: getTranslation("Purchased Price", t),
      existing: financeData?.existing?.purchasePriceFormatted
        ? formatCurrency(
            financeData?.existing?.purchasePriceFormatted,
            currencyOptions.symbol
          )
        : null,
      proposed: financeData?.proposed?.purchasePriceFormatted
        ? `${formatCurrency(
            financeData?.proposed?.purchasePriceFormatted,
            currencyOptions.symbol
          )}*`
        : null
    },
    {
      details: getTranslation("Total Deposit", t),
      existing: financeData?.existing?.totalDepositFormatted
        ? formatCurrency(
            financeData?.existing?.totalDepositFormatted,
            currencyOptions.symbol
          )
        : "N/A",
      proposed: financeData?.proposed?.totalDepositFormatted
        ? formatCurrency(
            financeData?.proposed?.totalDepositFormatted,
            currencyOptions.symbol
          )
        : formatCurrency(0, currencyOptions.symbol)
    },
    {
      details: getTranslation("Total manufacturer deposit", t),
      existing: "N/A",
      proposed: financeData?.proposed?.totalManufacturerDepositFormatted
        ? formatCurrency(
            financeData?.proposed?.totalManufacturerDepositFormatted,
            currencyOptions.symbol
          )
        : formatCurrency(0, currencyOptions.symbol)
    },
    {
      details: getTranslation("Total dealer deposit", t),
      existing: "N/A",
      proposed: financeData?.proposed?.totalDealerDepositFormatted
        ? formatCurrency(
            financeData?.proposed?.totalDealerDepositFormatted,
            currencyOptions.symbol
          )
        : formatCurrency(0, currencyOptions.symbol)
    },
    {
      details: getTranslation("Total customer deposit", t),
      existing: "N/A",
      proposed: financeData?.proposed?.totalCustomerDepositFormatted
        ? formatCurrency(
            financeData?.proposed?.totalCustomerDepositFormatted,
            currencyOptions.symbol
          )
        : formatCurrency(0, currencyOptions.symbol)
    },
    {
      details: getTranslation("Total amount financed", t),
      existing: financeData?.existing?.totalAmountFinancedFormatted
        ? formatCurrency(
            financeData?.existing?.totalAmountFinancedFormatted,
            currencyOptions.symbol
          )
        : null,
      proposed: financeData?.proposed?.totalAmountFinancedFormatted
        ? `${formatCurrency(
            financeData?.proposed?.totalAmountFinancedFormatted,
            currencyOptions.symbol
          )}*`
        : null
    },
    {
      details: getTranslation("Guaranteed Future Value (GFV)", t),
      existing: financeData?.existing?.gfvFormatted
        ? formatCurrency(
            financeData?.existing?.gfvFormatted,
            currencyOptions.symbol
          )
        : formatCurrency(0, currencyOptions.symbol),
      proposed: financeData?.proposed?.gfvFormatted
        ? formatCurrency(
            financeData?.proposed?.gfvFormatted,
            currencyOptions.symbol
          )
        : formatCurrency(0, currencyOptions.symbol)
    },
    {
      details: getTranslation("Upfront Fees", t),
      existing: financeData?.existing?.upfrontFeesFormatted
        ? formatCurrency(
            financeData?.existing?.upfrontFeesFormatted,
            currencyOptions.symbol
          )
        : financeData?.existing?.upfrontFeesFormatted === 0
        ? formatCurrency(0, currencyOptions.symbol)
        : "N/A",
      proposed: financeData?.proposed?.upfrontFeesFormatted
        ? formatCurrency(
            financeData?.proposed?.upfrontFeesFormatted,
            currencyOptions.symbol
          )
        : financeData?.proposed?.upfrontFeesFormatted === 0
        ? formatCurrency(0, currencyOptions.symbol)
        : "N/A"
    },
    {
      details: getTranslation("Monthly payment", t),
      existing: financeData?.existing?.monthlyPaymentFormatted
        ? formatCurrency(
            financeData?.existing?.monthlyPaymentFormatted,
            currencyOptions.symbol
          )
        : null,
      proposed: financeData?.proposed?.monthlyPaymentFormatted
        ? formatCurrency(
            financeData?.proposed?.monthlyPaymentFormatted,
            currencyOptions.symbol
          )
        : null
    },
    {
      details: getTranslation("Monthly difference", t),
      existing: "N/A",
      proposed: financeData?.proposed?.monthlyDifferenceFormatted
        ? formatCurrency(
            financeData?.proposed?.monthlyDifferenceFormatted,
            currencyOptions.symbol
          )
        : null
    },
    {
      details: getTranslation("Deposit to match payment/Cashback potential", t),
      existing: "N/A",
      proposed: financeData?.proposed?.cashbackFormatted
        ? formatCurrency(
            financeData?.proposed?.cashbackFormatted,
            currencyOptions.symbol
          )
        : null
    }
  ];

  // Add styling on table rows
  const rowRender = (trElement, props) => {
    const trProps = {
      style: {
        backgroundColor: "#ffff",
        color: "#00233AE5"
      }
    };

    const tdProps = {
      style: {
        borderRightWidth: 0,
        borderLeftWidth: 0,
        borderBottomWidth: 1,
        height: 57
      }
    };
    return React.cloneElement(
      trElement,
      {
        ...trProps
      },
      trElement.props.children.map((tdElement) => {
        return React.cloneElement(
          tdElement,
          {
            ...tdProps
          },
          tdElement.props.children
        );
      })
    );
  };

  // Render table header
  const headerCell = (props) => {
    const { title } = props;

    return (
      <div className="tableHeaderCell">
        <label
          className={
            title === "Proposed"
              ? `${"headerTitle"} ${"proposedHeaderTitle"}`
              : "headerTitle"
          }
        >
          <span>{getTranslation(title, t)}</span>
        </label>
      </div>
    );
  };

  //Render table row
  const rowCell = (props) => {
    const { dataItem, field } = props;
    const { details } = dataItem;
    const newUsedDetails =
      details === getTranslation("Registration date", t) ||
      details === getTranslation("Start date", t) ||
      details === getTranslation("Start kilometers", t) ||
      details === getTranslation("Start age", t);
    const equityDetails =
      details === getTranslation("Trade-in Value", t) ||
      details === getTranslation("Settlement Value", t);
    const totalDepositDetails =
      details === getTranslation("Total manufacturer deposit", t) ||
      details === getTranslation("Total dealer deposit", t) ||
      details === getTranslation("Total customer deposit", t);
    const termTotalKilometerDetails =
      details === getTranslation("Term", t) ||
      details === getTranslation("Total Kilometers", t);

    if (
      (details === getTranslation("Upfront Fees", t) ||
        details === getTranslation("Full Name", t) ||
        details === getTranslation("Comparison Rate", t)) &&
      !checkMarketAu()
    ) {
      return;
    }

    return (
      //add rows to hide
      <td
        hidden={
          (showNewUsedDropdown && newUsedDetails) ||
          (showEquityDropdown && equityDetails) ||
          (showTotalDepositDropdown && totalDepositDetails) ||
          (showTermKilometerDropdown && termTotalKilometerDetails)
            ? "hidden"
            : ""
        }
        className={
          newUsedDetails ||
          equityDetails ||
          totalDepositDetails ||
          termTotalKilometerDetails
            ? `${"rowCell"} ${"expandedDetails"}`
            : "rowCell"
        }
      >
        <div
          className={
            details === getTranslation("Monthly payment", t) &&
            field !== "existing"
              ? "monthlyPayment"
              : details === getTranslation("Monthly payment", t) &&
                field === "existing"
              ? "monthlyPaymentAmount"
              : field === "proposed"
              ? "proposedRow"
              : field === "details" &&
                (newUsedDetails ||
                  equityDetails ||
                  totalDepositDetails ||
                  termTotalKilometerDetails)
              ? "expandedDetailsRow"
              : " "
          }
        >
          {field === "details" ? dataItem[field] : dataItem[field]}
          {
            // Add dropdown caret button
            (details === getTranslation("New/Used", t) &&
              field === "details") ||
            (details === getTranslation("Equity", t) && field === "details") ||
            (details === getTranslation("Total Deposit", t) &&
              field === "details") ||
            (details === getTranslation("Term/Total Km", t) &&
              field === "details") ? (
              <button
                className={"caretIconBtn"}
                onClick={() => {
                  handleDropdowns(details);
                }}
              >
                <img
                  src={
                    (showNewUsedDropdown &&
                      details === getTranslation("New/Used", t)) ||
                    (showEquityDropdown &&
                      details === getTranslation("Equity", t)) ||
                    (showTotalDepositDropdown &&
                      details === getTranslation("Total Deposit", t)) ||
                    (showTermKilometerDropdown &&
                      details === getTranslation("Term/Total Km", t))
                      ? getIcon("caret-down.svg")
                      : getIcon("caret-up.svg")
                  }
                  alt="caretIcon"
                />
              </button>
            ) : null
          }
        </div>
      </td>
    );
  };

  //Handles dropdowns in Finance record
  const handleDropdowns = (details) => {
    if (details === getTranslation("New/Used", t)) {
      setShowNewUsedDropdown(!showNewUsedDropdown);
    } else if (details === getTranslation("Equity", t)) {
      setShowEquityDropdown(!showEquityDropdown);
    } else if (details === getTranslation("Total Deposit", t)) {
      setShowTotalDepositDropdown(!showTotalDepositDropdown);
    } else if (details === getTranslation("Term/Total Km", t)) {
      setShowTermKilometerDropdown(!showTermKilometerDropdown);
    }
  };

  return (
    <>
      {validateUserPermission(currentUser.permissions, "canEdit", "Leads") && financeData && (
        <button
          onClick={() =>
            Navigate(`/customer-record/${id}/edit-proposed-deal`, {
              state: { prevPage: location.state?.prevPage }
            })
          }
          className="editDeal-btn primary-btn"
          disabled={status === 1}
        >
          {getTranslation("Edit deal", t)}
        </button>
      )}
      {validateUserPermission(
        currentUser.permissions,
        "canCreate",
        "Leads"
      ) && (
        <button
          className="deskingSheet-btn secondary-btn"
          disabled={status === 1}
          onClick={exportDeskingSheet}
        >
          {getTranslation("Desking sheet", t)}
        </button>
      )}
      <div className="grid-container">
        <Grid
          className="financeRecordTableGrid"
          data={financeRecordData}
          rowRender={rowRender}
        >
          <GridColumn
            headerCell={headerCell}
            cell={rowCell}
            field="details"
            title="Details"
            width="200px"
          />
          <GridColumn
            headerCell={headerCell}
            cell={rowCell}
            field="existing"
            title="Existing"
            width="180px"
          />
          <GridColumn
            headerCell={headerCell}
            cell={rowCell}
            field="proposed"
            title="Proposed"
            width="180px"
          />
        </Grid>
      </div>
      <div>
        <div className="desking-sheet">
          <PDFExport
            ref={pdfExportComponent}
            paperSize="A4"
            margin={{ top: 20, bottom: 20, left: 44, right: 44 }}
            fileName={`${customerRecordData.customerName} ${format(
              new Date(),
              "dd.MM.yyyy HH.mm"
            )}.pdf`}
            author="Retention Engine - Inchcape Digital Delivery Center"
          >
            <DeskingSheet
              marketSettings={marketSettings}
              financeData={financeData}
              currentUser={currentUser}
              status={clientPositionData.status}
              customerRecordData={customerRecordData}
              clientPosition={clientPositionData}
            />
          </PDFExport>
        </div>
      </div>
    </>
  );
};

export default FinanceRecord;
