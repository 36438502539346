/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "../../styles/campaigns/campaignBuilder.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getIcon } from "../../utils/iconUtils";
import BuilderInputs from "./BuilderInputs";
import BuilderReplaceDeal from "./BuilderReplaceDeal";
import BuilderExistingDeal from "./BuilderExistingDeal";
import SavingModal from "../shared/SavingModal";
import PreviewLeadsModal from "./PreviewLeadsModal";
import VehicleSelector from "./VehicleSelector";
import fetchClient from "../../api/fetch";
import { format } from "date-fns";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";
import { checkMarketAu } from "../../utils/helpers";
import InterestRateModalConfirmation from "./InterestRateModalConfirmation";

const CampaignBuilder = ({
  currentUser,
  getVehicleSelectorList,
  vehicleSelectorList,
  getLeadsCount,
  getLeadsList,
  leadsList,
  leadsListPage,
  leadsCount,
  leadsReferenceKey,
  marketSettings,
  getUserBranches,
  userBranches,
  setSuccessModalValues,
  exportCampaignToExcel,
  setLoading
}) => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  //Mode for Campaign Builder and Campaign Edit
  const mode = location.pathname === "/campaigns/new" ? "create" : "edit";
  const initialVSDropdownValues = {
    brand: "All",
    model: "All",
    version: "All",
    fuelType: "All",
    transmission: "All"
  };
  const initialPayload = {
    campaignName: "",
    validFrom: null,
    validTo: null,
    branchId: null,
    replacementFinanceType: 1,
    replacementManufacturerDeposit: null,
    replacementManufacturerDepositPercentage: false,
    replacementFinanceTerm: null,
    replacementInterestRate: marketSettings.defaultInterestRate,
    replacementDealerDeposit: null,
    replacementDealerDepositPercentage: false,
    replacementMonthlyPaymentDifference: null,
    monthlyPaymentDifferencePercentage: false,
    additionalCustomerDeposit: null,
    existingFinanceType: 1,
    existingRegistrationDateFrom: null,
    existingRegistrationDateTo: null,
    existingOriginalFinanceTermFrom: null,
    existingOriginalFinanceTermTo: null,
    existingPercentElapsedFrom: null,
    existingPercentElapsedTo: null,
    existingMonthsRemainingFrom: null,
    existingMonthsRemainingTo: null,
    campaignReplacementDealFilter: initialVSDropdownValues,
    campaignExistingDealFilter: initialVSDropdownValues,
    campaignReplacementVehicles: checkMarketAu() ? null : [],
    campaignExistingVehicles: []
  };

  //useStates]
  const [dialogValues, setDialogValues] = useState({
    success: false,
    back: false,
    replacementVS: false,
    existingVS: false
  });
  const [findLeads, setFindLeads] = useState(false);
  const [showPreviewLeads, setShowPreviewLeads] = useState(false);
  const [showCampaignNameExistError, setShowCampaignNameExistError] =
    useState(false);
  // unselected if vehicle selector ids is []
  const [uncheckedIds, setUncheckedIds] = useState({
    replacementVS: [],
    existingVS: []
  });
  // total records
  const [totalRecords, setTotalRecords] = useState({
    replacementVS: 0,
    existingVS: 0,
    replacementCurrent: 0,
    existingCurrent: 0
  });
  const [vsHasFilters, setVSHasFilters] = useState(false);
  const [payload, setPayload] = useState(initialPayload);
  const [campaignName, setCampaignName] = useState("");
  const [inputError, setInputError] = useState({});
  const [invalidDate, setInvalidDate] = useState("");
  const [invalidRegDate, setInvalidRegDate] = useState("");
  const [branchesValue, setBranchesValue] = useState({
    branchDescription: getTranslation("Select", t)
  });
  const [financeTypeValue, setFinanceTypeValue] = useState({
    replacementFinanceType: {
      value: 1,
      financeType: "All",
      textField: getTranslation("All", t)
    },
    existingFinanceType: {
      value: 1,
      financeType: "All",
      textField: getTranslation("All", t)
    }
  });
  const [dateValue, setDateValue] = useState({
    start: null,
    end: null
  });
  const [existRegDate, setExistRegDate] = useState({
    start: null,
    end: null
  });
  const [financeTypeData, setFinanceTypeData] = useState([]);
  const [overrideInterestRate, setOverrideInterestRate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const payloadHolder = useRef(payload);
  const uncheckedHolder = useRef(uncheckedIds);
  const tempFinanceTypeIds = useRef({
    replacement: null,
    existing: null
  });

  useEffect(() => {
    if (Object.keys(inputError).length === 0 && isSubmit) {
      sendData();
    } else {
      setIsSubmit(false);
    }
  }, [isSubmit]);

  useEffect(() => {
    fetchFinanceTypes();
  }, []);

  useEffect(() => {
    if (
      mode === "edit" &&
      tempFinanceTypeIds.current.replacement &&
      tempFinanceTypeIds.current.existing
    ) {
      setFinanceTypeValue({
        replacementFinanceType: financeTypeData.find(
          (item) => item.value === tempFinanceTypeIds.current.replacement
        ),
        existingFinanceType: financeTypeData.find(
          (item) => item.value === tempFinanceTypeIds.current.existing
        )
      });

      tempFinanceTypeIds.current.replacement = null;
      tempFinanceTypeIds.current.existing = null;
    }
  }, [financeTypeData]);

  useEffect(() => {
    setFinanceTypeValue({
      replacementFinanceType: {
        ...financeTypeValue.replacementFinanceType,
        textField: getTranslation(
          financeTypeValue.replacementFinanceType.financeType,
          t
        )
      },
      existingFinanceType: {
        ...financeTypeValue.existingFinanceType,
        textField: getTranslation(
          financeTypeValue.existingFinanceType.financeType,
          t
        )
      }
    });
  }, [t]);

  useEffect(() => {
    if (mode === "edit") {
      setTotalRecords({
        replacementVS: vehicleSelectorList.listPage.replacement.totalRecords,
        existingVS: vehicleSelectorList.listPage.existing.totalRecords,
        replacementCurrent: vehicleSelectorList.listPage.replacement.currentTotalRecords,
        existingCurrent: vehicleSelectorList.listPage.existing.currentTotalRecords
      });
    }
  }, [vehicleSelectorList.listPage]);

  useEffect(() => {
    setPayload({
      ...payload,
      replacementInterestRate: marketSettings.defaultInterestRate
    });
  }, [marketSettings]);

  useEffect(() => {
    if (mode === "edit") {
      setLoading(true);
      fetchClient()
        .get(`/v1/campaigns/${id}`)
        .then((res) => {
          const resReplacementVS = res.data.data.campaignReplacementVehicle;
          const resExistingVS = res.data.data.campaignExistingVehicle;
          // if vehicleSelector ids is not empty, check isSelected
          // if 0, those are unselected Ids
          // if 1, those are selected Ids
          const data = {
            ...res.data.data,
            // replacementInterestRate:
            //   res.data.data.replacementInterestRate * 100,
            campaignReplacementVehicles:
              resReplacementVS.campaignReplacementVehicles === null
                ? null
                : resReplacementVS.campaignReplacementVehicles.length > 0
                ? resReplacementVS.isSelected
                  ? resReplacementVS.campaignReplacementVehicles
                  : []
                : [],
            campaignExistingVehicles:
              resExistingVS.campaignExistingVehicles.length > 0
                ? resExistingVS.isSelected
                  ? resExistingVS.campaignExistingVehicles
                  : []
                : [],
            campaignReplacementDealFilter: res.data.data
              .campaignReplacementDealFilter
              ? res.data.data.campaignReplacementDealFilter
              : initialVSDropdownValues,
            campaignExistingDealFilter: res.data.data.campaignExistingDealFilter
              ? res.data.data.campaignExistingDealFilter
              : initialVSDropdownValues
          };

          // Navigate back to campaigns if automatic campaigns are directly accessed
          if (data.campaignType !== 1) {
            Navigate("/campaigns");
          }

          if (!resReplacementVS.isSelected) {
            setUncheckedIds((prevState) => ({
              ...prevState,
              replacementVS: resReplacementVS.campaignReplacementVehicles
            }));
            uncheckedHolder.current = {
              ...uncheckedHolder.current,
              replacementVS: resReplacementVS.campaignReplacementVehicles
            };
          }

          if (!resExistingVS.isSelected) {
            setUncheckedIds((prevState) => ({
              ...prevState,
              existingVS: resExistingVS.campaignExistingVehicles
            }));
            uncheckedHolder.current = {
              ...uncheckedHolder.current,
              existingVS: resExistingVS.campaignExistingVehicles
            };
          }

          delete data.campaignReplacementVehicle;
          delete data.campaignExistingVehicle;

          const filters = [
            data.campaignReplacementDealFilter,
            data.campaignExistingDealFilter
          ];
          const unincludedFields = ["campaign", "id", "campaignId"];
          let replacementParams = "",
            existingParams = "";
          filters.forEach((arr, idx) => {
            for (const item in arr) {
              if (!unincludedFields.includes(item)) {
                if (idx === 0) {
                  replacementParams +=
                    arr[item] === "All"
                      ? ""
                      : `${replacementParams ? "&" : "?"}${item}=${arr[item]}`;
                } else {
                  existingParams +=
                    arr[item] === "All"
                      ? ""
                      : `${existingParams ? "&" : "?"}${item}=${arr[item]}`;
                }
              }
            }
          });
          // if not all are selected
          // get total records so number of selected items can be displayed
          const getTotalRecords = () => {
            getVehicleSelectorList({
              mode: "existing",
              param: existingParams
            });
            getVehicleSelectorList({
              mode: "replacement",
              param: replacementParams
            });
          };
          getTotalRecords();

          setPayload(data);
          setCampaignName(data.campaignName);
          setDateValue({
            start: new Date(data.validFrom),
            end: new Date(data.validTo)
          });

          if (data.branchId) {
            setBranchesValue({
              branchDescription: data.branchName,
              branchId: data.branchId
            });
          } else {
            setBranchesValue({
              branchDescription: getTranslation("Select", t)
            });
          }

          tempFinanceTypeIds.current.replacement = data.replacementFinanceType;
          tempFinanceTypeIds.current.existing = data.existingFinanceType;

          setExistRegDate({
            start: data.existingRegistrationDateFrom
              ? new Date(data.existingRegistrationDateFrom)
              : null,
            end: data.existingRegistrationDateTo
              ? new Date(data.existingRegistrationDateTo)
              : null
          });
          payloadHolder.current = data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoading(false));
    }
  }, []);

  //Handle page back
  const handleBack = () => {
    if (
      JSON.stringify(payload) === JSON.stringify(initialPayload) ||
      JSON.stringify(payloadHolder.current) === JSON.stringify(payload)
    ) {
      Navigate(mode === "create" ? "/campaigns" : `/campaigns/${id}/leads`, {
        state: { prevPage: location.state?.prevPage }
      });
    } else {
      setDialogValues({ ...dialogValues, back: true });
    }
  };

  //Handle Change in branch dropdown
  const handleBranchChange = (e) => {
    setBranchesValue(e.target.value);
    setPayload({ ...payload, [e.target.name]: e.target.value.branchId });
  };

  //Handle opening of branch dropdown
  const handleDropdownBranchOpen = () => {
    const brandId = currentUser.settings.brandId;
    getUserBranches(brandId);
  };

  //Handle FinanceType Dropdown
  const handleDropdownChange = (e) => {
    setPayload({ ...payload, [e.target.name]: e.target.value.value });
    setFinanceTypeValue({
      ...financeTypeValue,
      [e.target.name]: {
        ...e.target.value,
        financeType: getTranslation(e.target.value.financeType, t)
      }
    });
  };

  //Handle two decimal places change in value
  const handleDecimalNumberChange = (e) => {
    let num = e.target.value;
    let value =
      num.indexOf(".") >= 0
        ? num.substr(0, num.indexOf(".")) + num.substr(num.indexOf("."), 3)
        : num;

    let maxValue = 99999;

    if (
      e.target.name === "replacementManufacturerDeposit" &&
      payload.replacementManufacturerDepositPercentage
    ) {
      maxValue = 100;
    } else if (
      e.target.name === "replacementDealerDeposit" &&
      payload.replacementDealerDepositPercentage
    ) {
      maxValue = 100;
    } else if (
      e.target.name === "replacementMonthlyPaymentDifference" &&
      payload.monthlyPaymentDifferencePercentage
    ) {
      maxValue = 100;
    }

    if (value === "") {
      value = null;
    }

    if (value === null || value <= maxValue) {
      setPayload({
        ...payload,
        [e.target.name]: value
      });
    }
  };

  //Handle change for interest
  const handleInterestRate = (e) => {
    if (e.target.value <= 1) {
      setPayload({ ...payload, [e.target.name]: e.target.value });
    } else {
      setPayload({ ...payload, [e.target.name]: 0 });
    }
  };

  //Prevent negative values and diff. char types
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  //Prevent DOT, negative values and diff. char types
  const blockDotInvalidChar = (e) =>
    ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();

  //Handle whole number change in value
  const handleIntNumberChange = (e) => {
    setPayload({
      ...payload,
      [e.target.name]: e.target.value ? e.target.value : null
    });
  };

  //Handle checkbox value
  const handleCheckboxInputChange = (e) => {
    const { name, value } = e.target;
    let inputToClear = "";

    if (name === "replacementManufacturerDepositPercentage") {
      inputToClear = "replacementManufacturerDeposit";
    } else if (name === "replacementDealerDepositPercentage") {
      inputToClear = "replacementDealerDeposit";
    } else if (name === "monthlyPaymentDifferencePercentage") {
      inputToClear = "replacementMonthlyPaymentDifference";
    }

    setPayload({
      ...payload,
      [name]: value,
      [inputToClear]: ""
    });
  };

  //Fetch finance type data according to market
  const fetchFinanceTypes = () =>
    fetchClient()
      .get("v1/common/financetypes")
      .then((res) => {
        setFinanceTypeData(
          res.data.data.map((item) => ({
            ...item,
            textField: getTranslation(item.financeType, t)
          }))
        );
      })
      .catch((err) => console.log(err));

  //Handle open finance type dropdown
  const handleOpenFinanceTypeDropdown = () => fetchFinanceTypes();

  //Handle Input data in builder
  const handleDateChange = (e) => {
    if (
      [
        "validTo",
        "validFrom",
        "existingRegistrationDateFrom",
        "existingRegistrationDateTo"
      ].includes(e.target.name)
    ) {
      switch (e.target.name) {
        case "validFrom":
          if (dateValue.end !== null) {
            if (dateValue.end > e.target.value) {
              setPayload({
                ...payload,
                validFrom: format(e.target.value, "yyyy-MM-dd")
              });
              setDateValue({ ...dateValue, start: e.target.value });
            } else {
              setInvalidDate(getTranslation("campaign date error", t));
              setTimeout(() => {
                setInvalidDate("");
              }, 3000);
            }
          } else {
            setPayload({
              ...payload,
              validFrom: format(e.target.value, "yyyy-MM-dd")
            });
            setDateValue({ ...dateValue, start: e.target.value });
          }
          break;
        case "validTo":
          if (dateValue.start !== null) {
            if (dateValue.start < e.target.value) {
              setPayload({
                ...payload,
                validTo: format(e.target.value, "yyyy-MM-dd")
              });
              setDateValue({ ...dateValue, end: e.target.value });
            } else {
              setInvalidDate(getTranslation("campaign date error", t));
              setTimeout(() => {
                setInvalidDate("");
              }, 3000);
            }
          } else {
            setPayload({
              ...payload,
              validTo: format(e.target.value, "yyyy-MM-dd")
            });
            setDateValue({ ...dateValue, end: e.target.value });
          }
          break;
        case "existingRegistrationDateFrom":
          if (existRegDate.end !== null) {
            if (existRegDate.end > e.target.value) {
              setPayload({
                ...payload,
                existingRegistrationDateFrom: format(
                  e.target.value,
                  "yyyy-MM-dd"
                )
              });
              setExistRegDate({ ...existRegDate, start: e.target.value });
            } else {
              setInvalidRegDate(getTranslation("vehicle reg date error", t));
              setTimeout(() => {
                setInvalidRegDate("");
              }, 3000);
            }
          } else {
            setPayload({
              ...payload,
              existingRegistrationDateFrom: format(e.target.value, "yyyy-MM-dd")
            });
            setExistRegDate({ ...existRegDate, start: e.target.value });
          }
          break;
        case "existingRegistrationDateTo":
          if (existRegDate.start !== null) {
            if (existRegDate.start < e.target.value) {
              setPayload({
                ...payload,
                existingRegistrationDateTo: format(e.target.value, "yyyy-MM-dd")
              });
              setExistRegDate({ ...existRegDate, end: e.target.value });
            } else {
              setInvalidRegDate(getTranslation("vehicle reg date error", t));
              setTimeout(() => {
                setInvalidRegDate("");
              }, 3000);
            }
          } else {
            setPayload({
              ...payload,
              existingRegistrationDateTo: format(e.target.value, "yyyy-MM-dd")
            });
            setExistRegDate({ ...existRegDate, end: e.target.value });
          }
          break;
        default:
          break;
      }
    } else {
      setPayload({ ...payload, [e.target.name]: e.target.value });
      setShowCampaignNameExistError(false);
    }
    validateInput(e.target.value, e.target.name);
  };

  //Input validation campaign builder
  const validateInput = (value, name = "") => {
    let err = !name ? {} : { ...inputError };
    if (name) {
      if (!value) {
        err.campaignName = getTranslation("Please add a campaign name.", t);
      } else if (name === "campaignName") {
        delete err.campaignName;
      }
      if (value === null) {
        err.validFrom = getTranslation("Please add a valid date.", t);
      } else if (name === "validFrom") {
        delete err.validFrom;
      }
      if (value === null) {
        err.validTo = getTranslation("Please add a valid date.", t);
      } else if (name === "validTo") {
        delete err.validTo;
      }
    } else {
      if (!payload.campaignName) {
        err.campaignName = getTranslation("Please add a campaign name.", t);
      }
      if (payload.validFrom === null) {
        err.validFrom = getTranslation("Please add a valid date.", t);
      }
      if (payload.validTo === null) {
        err.validTo = getTranslation("Please add a valid date.", t);
      }
    }
    setInputError({ ...err });
  };

  const sendData = () => {
    // const items = {
    //   ...payload,
    //   replacementInterestRate: payload.replacementInterestRate / 100
    // };
    let isSelectedReplacementId = payload.campaignReplacementVehicles
      ? payload.campaignReplacementVehicles?.length > 0
      : false;
    let isSelectedIvId = payload.campaignExistingVehicles?.length > 0;
    let params = `?isSelectedReplacementId=${isSelectedReplacementId}&isSelectedIvId=${isSelectedIvId}`;
    if (uncheckedIds.replacementVS?.length > 0) {
      params += `&replacementVehicleId=${uncheckedIds.replacementVS.toString()}`;
    }
    if (uncheckedIds.existingVS.length > 0) {
      params += `&unselectedIvId=${uncheckedIds.existingVS.toString()}`;
    }

    if (mode === "create") {
      fetchClient()
        .post("/v1/campaigns" + params, payload)
        .then((res) => {
          setSuccessModalValues({
            message: getTranslation("Campaign saved successfully", t),
            isVisible: true
          });
          setTimeout(() => {
            setSuccessModalValues({ message: "", isVisible: false });
            Navigate(
              mode === "create" ? "/campaigns" : `/campaigns/${id}/leads`,
              {
                state: { prevPage: location.state?.prevPage }
              }
            );
          }, 3000);
          setPayload(initialPayload);
          setDateValue({ start: null, end: null });
          setExistRegDate({ start: null, end: null });
        })
        .catch((err) => {
          validateInput();
          if (err.response.data.message === "Campaign Name already exists.") {
            setIsSubmit(false);
            setShowCampaignNameExistError(true);
          }
          console.log(err);
        });
    } else {
      fetchClient()
        .put(`/v1/campaigns/${id}${params}`, payload)
        .then((res) => {
          setSuccessModalValues({
            message: getTranslation("Campaign saved successfully", t),
            isVisible: true
          });
          setTimeout(() => {
            setSuccessModalValues({ message: "", isVisible: false });
            Navigate(
              mode === "create" ? "/campaigns" : `/campaigns/${id}/leads`,
              {
                state: { prevPage: location.state?.prevPage }
              }
            );
          }, 3000);
          setShowCampaignNameExistError(false);
        })
        .catch((err) => {
          setShowCampaignNameExistError(true);
          setIsSubmit(false);
          console.log(err);
        });
    }
  };

  //Handle Campaign Save
  const handleSave = () => {
    if (
      Number(payload.replacementInterestRate * 100).toFixed(2) ===
        marketSettings.defaultInterestRateFormatted.toFixed(2) ||
      Number(payloadHolder.current.replacementInterestRate * 100).toFixed(2) ===
        Number(payload.replacementInterestRate * 100).toFixed(2)
    ) {
      setIsSubmit(true);
      validateInput();
    } else {
      setOverrideInterestRate(true);
    }
  };

  const handleProceedOverride = () => {
    setOverrideInterestRate(false);
    sendData();
  };

  const handleCancelModal = () => {
    setOverrideInterestRate(false);
  };

  //Handle finding leads
  const handleFindLeads = () => {
    const items = {
      ...payload,
      //replacementInterestRate: payload.replacementInterestRate / 100,
      replacementDealerDeposit:
        payload.replacementDealerDeposit === ""
          ? null
          : payload.replacementDealerDeposit,
      replacementManufacturerDeposit:
        payload.replacementManufacturerDeposit === ""
          ? null
          : payload.replacementManufacturerDeposit,
      replacementMonthlyPaymentDifference:
        payload.replacementMonthlyPaymentDifference === ""
          ? null
          : payload.replacementMonthlyPaymentDifference
    };
    validateInput();
    if (payload.campaignName && payload.validFrom && payload.validTo) {
      setFindLeads(true);
      getLeadsCount(items);
    }
  };

  //Handle open vehicle selector
  const handleShowVS = (dealType) =>
    setDialogValues({ ...dialogValues, [dealType]: !dialogValues[dealType] });

  const handleExportCampaign = () => {
    exportCampaignToExcel(id);
  };

  return (
    <div className="campaign-builder">
      <div className="d-flex gap-5 align-items-center mb-4 header">
        <img
          onClick={handleBack}
          src={getIcon("arrow-back.svg")}
          alt="back icon"
        />
        <p className="h1">
          {mode === "create"
            ? getTranslation("Campaign builder", t)
            : `${getTranslation("Edit", t)} (${campaignName})`}
        </p>
      </div>
      <div className="campaign-builder-cont w-100 ">
        <div className="builder-input ">
          <BuilderInputs
            mode={mode}
            currentUser={currentUser}
            showCampaignNameExistError={showCampaignNameExistError}
            payload={payload}
            handleDateChange={handleDateChange}
            dateValue={dateValue}
            inputError={inputError}
            invalidDate={invalidDate}
            branchesValue={branchesValue}
            branchData={[
              { branchId: null, branchDescription: getTranslation("Select", t) }
            ].concat(userBranches)}
            handleDropdownBranchOpen={handleDropdownBranchOpen}
            handleBranchChange={handleBranchChange}
          />
        </div>
        <div className="builder-replace-deal ">
          <BuilderReplaceDeal
            showVS={() => handleShowVS("replacementVS")}
            financeTypeData={financeTypeData}
            financeTypeValue={financeTypeValue}
            handleOpenFinanceTypeDropdown={handleOpenFinanceTypeDropdown}
            handleDropdownChange={handleDropdownChange}
            marketSettings={marketSettings}
            payload={payload}
            totalRecords={totalRecords}
            uncheckedIds={uncheckedIds}
            mode={mode}
            handleDecimalNumberChange={handleDecimalNumberChange}
            handleIntNumberChange={handleIntNumberChange}
            handleCheckboxInputChange={handleCheckboxInputChange}
            blockInvalidChar={blockInvalidChar}
            blockDotInvalidChar={blockDotInvalidChar}
            handleInterestRate={handleInterestRate}
          />
        </div>
        <div className="builder-exist-deal">
          <BuilderExistingDeal
            mode={mode}
            showVS={() => handleShowVS("existingVS")}
            financeTypeData={financeTypeData}
            financeTypeValue={financeTypeValue}
            handleDropdownChange={handleDropdownChange}
            totalRecords={totalRecords}
            uncheckedIds={uncheckedIds}
            payload={payload}
            handleDecimalNumberChange={handleDecimalNumberChange}
            handleIntNumberChange={handleIntNumberChange}
            handleCheckboxInputChange={handleCheckboxInputChange}
            blockInvalidChar={blockInvalidChar}
            blockDotInvalidChar={blockDotInvalidChar}
            existRegDate={existRegDate}
            invalidRegDate={invalidRegDate}
            handleDateChange={handleDateChange}
            hasFilters={vsHasFilters}
          />
        </div>
        <div className="builder-buttons d-flex align-content-center justify-content-center">
          <button
            className="primary-btn"
            onClick={handleSave}
            disabled={
              (mode === "edit" &&
                JSON.stringify(uncheckedHolder.current) ===
                  JSON.stringify(uncheckedIds) &&
                JSON.stringify(payloadHolder.current) ===
                  JSON.stringify(payload)) ||
              payload.replacementInterestRate === null ||
              payload.replacementInterestRate <= 0
            }
          >
            {getTranslation("Save", t)}
          </button>
          {mode === "edit" && (
            <button className="secondary-btn" onClick={handleExportCampaign}>
              {getTranslation("Export", t)}
            </button>
          )}
          <button className="secondary-btn" onClick={() => handleFindLeads()}>
            {findLeads === false
              ? getTranslation("Find leads", t)
              : `${getTranslation("Find leads", t)} (${leadsCount})`}
          </button>
          <button
            className="secondary-btn"
            onClick={() => setShowPreviewLeads(true)}
            disabled={!leadsCount}
          >
            {getTranslation("Preview Leads", t)}
          </button>
        </div>
      </div>
      {dialogValues.back && (
        <SavingModal
          message={"Do you want to leave without saving?"}
          onclickHandleNo={() =>
            setDialogValues({ ...dialogValues, back: false })
          }
          onclickHandleYes={() =>
            Navigate(
              mode === "create" ? `/campaigns` : `/campaigns/${id}/leads`
            )
          }
        />
      )}
      {dialogValues.replacementVS && (
        <VehicleSelector
          initialDropdownValues={initialVSDropdownValues}
          uncheckedIds={uncheckedIds}
          setUncheckedIds={setUncheckedIds}
          payload={payload}
          totalRecords={totalRecords}
          setTotalRecords={setTotalRecords}
          setHasFilters={setVSHasFilters}
          setPayload={setPayload}
          vehicleSelectorList={vehicleSelectorList}
          getVehicleSelectorList={getVehicleSelectorList}
          handleShowVS={handleShowVS}
          vsType="replacementVS"
        />
      )}
      {dialogValues.existingVS && (
        <VehicleSelector
          initialDropdownValues={initialVSDropdownValues}
          uncheckedIds={uncheckedIds}
          setUncheckedIds={setUncheckedIds}
          payload={payload}
          totalRecords={totalRecords}
          setTotalRecords={setTotalRecords}
          setHasFilters={setVSHasFilters}
          setPayload={setPayload}
          vehicleSelectorList={vehicleSelectorList}
          getVehicleSelectorList={getVehicleSelectorList}
          handleShowVS={handleShowVS}
          vsType="existingVS"
        />
      )}
      {showPreviewLeads && (
        <PreviewLeadsModal
          overrideInterestRate={overrideInterestRate}
          setOverrideInterestRate={setOverrideInterestRate}
          setShowPreviewLeads={setShowPreviewLeads}
          setShowCampaignNameExistError={setShowCampaignNameExistError}
          setSuccessModalValues={setSuccessModalValues}
          leadsReferenceKey={leadsReferenceKey}
          getLeadsList={getLeadsList}
          leadsList={leadsList}
          leadsListPage={leadsListPage}
          uncheckedIds={uncheckedIds}
          marketSettings={marketSettings}
          payload={payload}
          payloadHolder={payloadHolder}
          mode={mode}
          id={id}
        />
      )}
      {overrideInterestRate && (
        <InterestRateModalConfirmation
          handleProceedSave={handleProceedOverride}
          handleCancelSave={handleCancelModal}
        />
      )}
    </div>
  );
};

export default CampaignBuilder;